.modal-wrapper_Dashboard{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    animation: fadeIn 0.9s ease-in-out;
    background-color: rgba(111, 111, 111, 0.54);
}



.modal-container_Dashboard_invoice{
    position: fixed;
    z-index: 999;
    right: -1%;
    top: 47%;
    transform: translateY(-50%);
    width: 40%;
    animation: slideInproduct 0.8s ease-in-out;
    box-shadow: 0px 0px 16px -11px;
}

@keyframes slideInproduct {
    from {
      right: -50%;
    }
    to {
      right: 0%;
    }
  }


.lspace{
    margin-left: 12px;
}
.palletMedia{
    padding: 2rem;
}
.dropDown{
    display: flex;
   margin-left: 11%;
   gap: 3%;
}
.wholeComp{
   margin-left: 20%;
   margin-right: 2%;
   margin-top: 5%;
   padding: 2rem;
}
.Porder{
    margin-top: auto;
    margin-bottom: 3%;
}
.twoDropdown{
    display: flex;
    gap: 2%;
    margin-bottom: 2%;
    margin-top: 5%;
    
    
}
.Pdate{
    margin-left: 4px;

}
.Thead{
    background-color: #6960EC;
}
.palletReport{
    margin-top: 5%;
    margin-left: 20%;
}
.palletTable{
    margin-left: 20%;
    margin-right: 1%;
    margin-top: 4%;
}
.daterangepicker{
    height: fit-content;
    background-color: rgb(212, 212, 212);
    cursor: pointer;
}
.inputFilterBY{
    margin-top: 3px;
}
.inputF{
    padding: 5px;
    border-radius: 4px;
    color: black;
}

.inputF:hover{
   background-color: rgb(212, 212, 212);
}

/* Define styles for smaller screens */
@media only screen and (max-width: 600px) {
    .daterangepicker {
      flex-direction: column; /* Stack items vertically */
      align-items: center; /* Center items horizontally */
    }
  
    .daterangepicker > div {
      margin-bottom: 10px; /* Add space between items */
    }
  
    .daterangepicker > div:first-child {
      margin-top: 10px; /* Add space above the date range display */
    }
  }
  
  /* Define styles for larger screens */
  @media only screen and (min-width: 601px) {
    .daterangepicker {
      flex-direction: row; /* Display items horizontally */
      justify-content: flex-start; /* Align items to the start of the container */
    }
  
    .daterangepicker > div {
      margin-right: 10px; /* Add space between items */
    }
  
    .daterangepicker > div:last-child {
      margin-right: 0; /* Remove margin from the last item */
    }
  }
  

@media screen and (max-width: 1200px){
    .wholeComp{
        margin-left: 25%;
    }
}
@media screen and (max-width: 1040px){
    .wholeComp{
        margin-left: 29%;
        margin-top: 7%;
    }
}
@media screen and (max-width: 992px){
    .wholeComp{
        margin-left: 2%;
        margin-right: 6%;
    }
}
@media screen and (max-width: 771px){
    .wholeComp{
        margin-top: 10%;
    }
}
@media screen and (max-width: 550px){
    .wholeComp{
        margin-top: 14%;
    }
}
@media screen and (max-width: 400px){
    .wholeComp{
        margin-top: 18%;
    }
}
@media screen and (max-width: 550px){
    .purchaseTable{
        margin-right: 3%;
    }
}
@media screen and (max-width: 484px){
    .purchaseTable{
        margin-right: 5%;
    }
}

@media screen and (max-width: 1200px){
    .palletMedia{
        margin-left: 7%;
        margin-top: 5%;
    }
}
@media screen and (max-width: 1030px){
    .palletMedia{
        margin-left: 9%;
        margin-top: 6%;
    }
}
@media screen and (max-width: 991px){
    .palletMedia{
        margin-left: 0%;
        margin-top: 10%;
        margin-right: 6;
    }
}
@media screen and (max-width: 991px){
    .palletTable{
        margin-left: 1%;
       
    }
}
@media screen and (max-width: 991px){
    .palletReport{
        
        margin-left: 1%;
    }
}

@media screen and (max-width: 608px){
    .palletMedia{
        margin-left: 0%;
        margin-top: 14%;
    }
}
@media screen and (max-width: 422px){
    .palletMedia{
     
        margin-top: 18%;
    }
}
@media screen and (max-width: 334px){
    .palletMedia{
     
        margin-top: 23%;
    }
}

@media screen and (max-width: 400px) {
    .input_popup_pallet{
        flex-direction: column;
        gap: 1rem;
    }
}

@media screen and (max-width: 1200px) {
    .popup_dashboard{
        width: 50%;
    }
}

@media screen and (max-width: 900px) {
    .popup_dashboard{
        width: 70%;
    }
}

@media screen and (max-width: 600px) {
    .popup_flex{
        flex-direction: column;
        gap: 1rem;
    }
}

@media screen and (max-width: 500px) {
    .popup_dashboard{
        width: 90%;
    }
}


@media screen and (max-width: 320px) {
    .popup_dashboard{
        width: 95%;
    }
}






  
  
 







