.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050;
  }
  
  .popup-content {
    width: 300px;
    margin: auto;
  }
  