/* printStyles.css */

/* Add this CSS to your stylesheet */

.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}



.page-wrapper {
  padding: 20px;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.page-title h4 {
  margin: 0;
  font-size: 24px;
  color: #3498db;
}

.page-title h6 {
  margin: 0;
  font-size: 14px;
  color: #7f8c8d;
}

.card {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.card-body {
  padding: 20px;
}

.productdetails {
  display: flex;
  flex-direction: column;
}

.product-bar {
  list-style: none;
  padding: 0;
}

.product-bar li {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #e3e3e3;
}

.product-bar li h4 {
  margin: 0;
  font-size: 16px;
  color: #34495e;
}

.product-bar li h6 {
  margin: 0;
  font-size: 16px;
  color: #2c3e50;
}

.bar-code-view {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.print-settings {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 0 10px;
}

.print-settings input[type="checkbox"] {
  margin-right: 5px;
}

.print-settings input[type="number"] {
  margin-left: 10px;
  width: 50px;
}

.print-settings label {
  margin: 0 5px;
}

.print-button {
  margin-left: auto;
}

.print-button button {
  border: 1px solid #3498db;
  border-radius: 5px;
  background: #3498db;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}


.modal-wrapper_Dashboard {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal-container_Dashboard_invoice {
  width: 50%;
  max-height: 90%;
  overflow-y: auto;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1rem;
}

.form-label {
  font-weight: bold;
}


.block_load_add_popup{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.load_popup_brand{
  overflow: scroll;
}

@media screen and (max-width: 500px) {
  .block_load_add_popup{
    grid-template-columns: 1fr 1fr ;
  }

  .modal-container_Dashboard_invoice {
    width: 80%;
    max-height: 90%;
    overflow-y: auto;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
}

@media print {

  .print_single {
    width: 6in;
    height: 5in;
    page-break-inside: avoid;
  }

  .print_single ul {
    padding: 0;
    margin: 0;
  }

  .print_single ul li {
    list-style: none;
  }

  .print_single ul li h4, .print_single ul li h6 {
    margin: 0;
    font-size: 5px;
    color: #000;
    padding: 0;
  }
}


@media print {

  .print_horizontal{
    display: flex;
  }

  .vertical .print_single {
    display: block;
    margin-bottom: 1rem;
  }

  .vertical .multi_print, .vertical .multi_print_only {
    display: block;
    margin-bottom: 1rem;
  }

  .vertical .print_single ul {
    display: block;
  }

  .vertical .print_single ul li {
    display: block;
    border-bottom: 1px solid #e3e3e3;
  }
}




